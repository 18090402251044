import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('FieldActionsFactory', FieldActionsFactory);


FieldActionsFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function FieldActionsFactory(fieldService, Configuration, $http) {
    let factory = this;

    factory.preventActionsFromProceeding = function(scanStationController, fieldId, scanStationFieldIdsToField, e, data, preventRuleCall, fieldSlug) {


        if((scanStationController.allowActionsAfterShowMore === false || scanStationController.allowActionsAfterEditClick === false) && scanStationFieldIdsToField[fieldId].overridePreventActions != 1) {
            return true
        }

        if(scanStationController.scanStationObj.isInEditMode == true && (scanStationFieldIdsToField[fieldId] != null && scanStationFieldIdsToField[fieldId].preventActionsFromTriggeringWhileEditing == true)) {
            return true;
        }

        // field parameter that prevents rules from being triggered in a sub bloc while filling out the form, but submits them afterwards
        // for sub blocs only
        if (scanStationFieldIdsToField[fieldId] != null && scanStationFieldIdsToField[fieldId].preventRuleSubmissionInSubBlocBeforeSubmitting && e != 'after-submission') {
            if ($('body').hasClass('blocworx-iframe')) {
                return true;
            }
        }

        // field parameter that prevents rules from being triggered while filling out the form, but submits them afterwards
        // for sub blocs only
        if (scanStationFieldIdsToField[fieldId] != null && scanStationFieldIdsToField[fieldId].preventRuleSubmissionInBeforeSubmitting && e != 'after-submission') {
            return true;
        }

        if (data == 'blocworx-unset-maths') {
            return true;
        }


        if (scanStationFieldIdsToField[fieldId] != null && scanStationFieldIdsToField[fieldId].preventRuleTriggerValues != null && scanStationFieldIdsToField[fieldId].preventRuleTriggerValues != '') {
            let ruleFirePreventionValues = scanStationFieldIdsToField[fieldId].preventRuleTriggerValues.split(',');
            if (ruleFirePreventionValues.includes(data)) {
                return true;
            }
        }


        // validation
        if (typeof (preventRuleCall[fieldSlug]) != 'undefined' && preventRuleCall[fieldSlug] == true) {
            return true;
        }

        if (e == 'no-event' || e == 'after-submission') {

            if ((data === null || data == '') && data !== 0) {
                // no event along with no data either, this means it came from a plain data field or some other field where there was no input or new data set
                // in this case there is no point checking the rules.
                // sometimes we still want to proceed even with nothing (so we can trigger other rules

                if(scanStationFieldIdsToField[fieldId] != null && scanStationFieldIdsToField[fieldId].forceActionsEvenWithNoValue == 1) {
                    return false;
                } else {
                    return true;
                }

            }
        }

        return false;

    }


    return factory;
}

