import * as angular from 'angular';

"use strict";

angular.module('ConfigurationModule')
    .provider('Configuration', Configuration);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @constructor
 */
function Configuration() {

    const service = this;
    const version = '3.9.0';
    service.apiUrl = '';
    service.country = '';
    service.flag = '';
    service.domainWithTDL = '';
    service.domain = window.location.hostname;
    service.version = version;
    service.timestamp = Date.now();
    service.subDomain = '';
    service.wsHost = process.env.WH_HOST;
    service.wsPort = process.env.WS_PORT;
    service.pusherAppId = process.env.PUSHER_APP_ID;
    service.pusherAppKey = process.env.PUSHER_APP_KEY;

    service.$get = ['AppSettingService', function (AppSettingService) {

        /* This service needs to go here as we need access to AppSettingService */

        service.getEvervaultInboundRelayUrl = function () {
            let evervaultUrl = AppSettingService.loadSingleAppSetting('evervault_url');
            // validating the url
            if(!evervaultUrl.includes('https') || !evervaultUrl.includes('relay.evervault.com')) {
                return service.apiUrl;
            } else {
                return evervaultUrl + '/api/';
            }
        };

        service.getEvervaultFieldSlugs = function () {
            let evervaultFieldSlugs = AppSettingService.loadSingleAppSetting('evervault_field_slugs');
            return evervaultFieldSlugs;
        };

        return {
            loadData: service.loadData,
            getVersion: service.getVersion,
            getDomain: service.getDomain,
            getApiUrl: service.getApiUrl,
            getSubDomain: service.getSubDomain,
            getDomainWithTDL: service.getDomainWithTDL,
            getRandomValue: service.getRandomValue,
            getWSPort: service.getWSPort,
            getWSHost: service.getWSHost,
            getPusherAppId: service.getPusherAppId,
            getPusherAppKey: service.getPusherAppKey,
            getEvervaultInboundRelayUrl: service.getEvervaultInboundRelayUrl,
            getEvervaultFieldSlugs: service.getEvervaultFieldSlugs

        }

    }];

    service.loadData = function () {

        if (service.apiUrl === '' || service.domainWithTDL === '') {

            let parts = service.domain.split('.');
            let sub = parts[0];

            // setting the subdomain data
            service.subDomain = sub;

            if (service.domainWithTDL === '') {
                service.domainWithTDL = parts[1] + '.' + parts[2];
            }
            if (service.apiUrl === '') {
                service.apiUrl = window.location.protocol + '//' + sub + "." + service.domainWithTDL + "/api/";
            }
        }
    }

    // loading data in the constructor
    service.loadData();

    // This will return is the version of the software
    service.getVersion = function () {
        return service.version;
    }

    // This will return what is the domain
    service.getDomain = function () {
        return service.domain;
    }

    // This will return what is base apiURL
    service.getApiUrl = function () {
        return service.apiUrl;
    }
    // This will return what is the subdomain
    service.getSubDomain = function () {
        return service.subDomain;
    }

    // This will return what is the domain with TDL option
    service.getDomainWithTDL = function () {
        return service.domainWithTDL;
    }

    // This will return a random value
    service.getRandomValue = function () {
        return (Math.random() * (1.00 - 0.00))
    }

    // This will return the Ws Host Setting
    service.getWSHost = function () {
        return service.wsHost;
    }

    // This will return the Ws Port
    service.getWSPort = function () {
        return service.wsPort;
    }

    // This will return the Pusher App ID
    service.getPusherAppId = function () {
        return service.pusherAppId;
    }

    // This will return the Pusher App Key
    service.getPusherAppKey = function () {
        return service.pusherAppKey;
    }

    return service;

}

export default Configuration;
