import * as angular from 'angular';

'use strict';

angular.module('DownloadModule')
       .service('DownloadService', DownloadService)
       .config(($compileProvider) => {
           $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
       });

DownloadService.$inject = ['$http', 'Configuration'];

function DownloadService($http, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * This will load the template file list.
     *
     * @param scanStationId
     */
    service.loadFileList = async (scanStationId) => {
        let apiBase = apiUrl + 'load-file-list';
        let config = {
            params: {
                scanStationId: scanStationId
            }
        };

        // loading the filelist
        let fileList = await $http.get(apiBase, config);

        return fileList.data;
    }


    /**
     * This will get the automatically chosen template file if applicable.
     *
     * @param scanStationId
     * @param dataToAdd
     * @param chosenTemplateFile
     * @param pdfOrWord
     * @param rowId
     * @param emailAddresses
     */
    service.getAutomaticallyChosenTemplateFile = async (scanStationId, dataToAdd, rowId) => {

        let apiBase = apiUrl + 'get-automatically-chosen-template-file';

        let body = {
            scanStationId: scanStationId,
            dataToAdd: dataToAdd,
            rowId: rowId,
        };

        let config = {
        };

        return $http.post(apiBase, body,config);

    }


    /**
     * This will start the download of a template file.
     *
     * @param scanStationId
     * @param dataToAdd
     * @param chosenTemplateFile
     * @param pdfOrWord
     * @param rowId
     * @param emailAddresses
     */
    service.templateDownload = async (scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord='docx', rowId, emailAddresses) => {

        let apiBase = apiUrl + 'template-download';
        let config = {
            responseType: 'blob'
        };

        let body = {
            scanStationId: scanStationId,
            dataToAdd: dataToAdd,
            chosenTemplateFile: chosenTemplateFile,
            pdfOrWord: pdfOrWord,
            rowId: rowId,
            emailAddresses: emailAddresses
        };

        return $http.post(apiBase, body, config);

    }

    /**
     * This will load the generated mock file.
     *
     * @param scanStationId
     * @param chosenTemplateFile
     */
    service.templateDownloadMock = async (scanStationId, chosenTemplateFile) => {

        let apiBase = apiUrl + 'template-download-mock';
        let config = {
            responseType: 'blob'
        };

        let body = {
            scanStationId: scanStationId,
            chosenTemplateFile: chosenTemplateFile
        };

        return $http.post(apiBase, body, config);

    }

    return service;

}

