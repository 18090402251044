import * as angular from 'angular';
import * as $ from "jquery";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('FieldActionsController', ExternalBlocDataController);

// Injection of each class
ExternalBlocDataController.$inject = [
    '$scope',
    'ScopeAndLocal',
    '$window',
    '$mdDialog',
    'ExternalBlocDataFactory'
];

// Controller Constructor
function ExternalBlocDataController($scope,
                                    ScopeAndLocal,
                                    $window,
                                    $mdDialog,
                                    ExternalBlocDataFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'main');


        } catch (e) {
            console.log(e);
        }
    }


    vm.showPopup = ($event) => {

        // set of the class for the #modal get it from the body class
        vm.classToSet = $('body').hasClass('dark') ? 'dark' : 'light';

        // Set of the class so we can have a modal with the current theme
        // some tables and other things rely on the top level skin setting which is declared outside of the skin-setting-dark class
        $('#modal').removeClass()
            .addClass(vm.classToSet)
            .addClass('skin-setting-' + vm.classToSet)
            .show();

        // Showing the alert dialog
        $mdDialog.show({
            controller: ExternalBlocDataController,
            controllerAs: 'ebd',
            templateUrl: 'components/external-bloc-data/template/popup.html',
            parent: angular.element(document.querySelector('#modal')),
            targetEvent: $event,
            scope: $scope,
            preserveScope: true,
        });

    }

    /**
     * This method will hide the actual alert that is open.
     * @param event
     */
    vm.hideAlert = async () => {
        $('#modal').removeClass(vm.classToSet).hide();
        vm.scanStation.showExternalBlocData = false;
        $mdDialog.cancel();
    }

    /**
     * This converts the string to usable json
     */
    vm.initialiseMatchFieldFieldPairs = () => {
        if(vm.scanStation.currentSelectedFieldParameters.externalBlocDatalocalFieldMatchingFieldPairs != null) {
            vm.scanStation.currentSelectedFieldParameters.externalBlocDatalocalFieldMatchingFieldPairs = JSON.parse(vm.scanStation.currentSelectedFieldParameters.externalBlocDatalocalFieldMatchingFieldPairs);
        }

    }

    /**
     * This function adds or remove the matching pairs of fields that are used to find out what to join in another bloc
     * @param localFieldMatchingFieldPairs
     * @param action
     * @param localField
     * @param remoteField
     * @param {any} index
     * @returns {Promise<any>}
     */
    vm.addOrRemoveExternalDataBlocMatchingFieldPairs = async function (action, localField, remoteField, index = null) {
        vm.scanStation.currentSelectedFieldParameters.externalBlocDatalocalFieldMatchingFieldPairs = await ExternalBlocDataFactory.addOrRemoveExternalDataBlocMatchingFieldPairs(vm.scanStation.currentSelectedFieldParameters.externalBlocDatalocalFieldMatchingFieldPairs, action, localField, remoteField, index);
        await vm.setScanStationFieldParameter('externalBlocDatalocalFieldMatchingFieldPairs');
        $scope.$apply();
    }


    /**
     * Update the field parameter
     */
    vm.setScanStationFieldParameter = async (parameter) => {

        let parameterInstruction = 'force-array-to-json-with-array';

        if(parameter == 'defaultDataIfNoMatchFound') {
          parameterInstruction = 'strip-quotes';
        }

        await vm.scanStation.setScanStationFieldParameter(vm.scanStation.fieldIDForUpdatingRules, parameter, vm.scanStation.currentSelectedFieldParameters[parameter], parameterInstruction);
        $scope.$apply();
    }

    /**
     * This method is used for updating the bloc selection
     * @returns {Promise<void>}
     */

    vm.updateBlocAndRetrieveFields = async () => {
        await vm.fc.getScanStationFields(vm.scanStation.currentSelectedFieldParameters.externalBlocDataLookUpStationId);
        await vm.setScanStationFieldParameter('externalBlocDataLookUpStationId');
        $scope.$apply();
    }

    /**
     * This is a generic function for showing the selected items in a select dropdown menu
     * @param modelValue
     * @param listItemValue
     * @returns {boolean}
     */
    vm.showSelected = (modelValue, listItemValue) => modelValue === listItemValue;


}

export default ExternalBlocDataController;