import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('PrintRuleController', PrintRuleController);

PrintRuleController.$inject = ['$scope', 'rulesFactory'];

// Controller Constructor
function PrintRuleController($scope, rulesFactory) {

    // Creating the view model reference
    let vm = this;
    vm.rule = {};
    vm.scanStation = {};
    vm.selectedOption = '';
    vm.rulesFactory = ''

    vm.$onInit = function () {
        try {

            $scope.rule = vm.rule;
            $scope.scanStation = vm.scanStation;
            vm.rulesFactory = rulesFactory;
            rulesFactory.loadScanStation(vm.scanStation).loadScope($scope);

        } catch (e) {
            console.log(e);
        }
    }
}

export default PrintRuleController;