import * as angular from 'angular';

'use strict';

angular.module('FieldModule')
       .factory('rulesFactory', Rules);

Rules.$inject = [];

function Rules() {

    let service = this;
    service.scanStation = false;
    service.scope = false;

    /**
     * This will load from a controller perspective the object
     * ScanStation.
     *
     * @param scanStation
     */
    service.loadScanStation = (scanStation) => {
        service.scanStation = scanStation;
        return service;
    }

    /**
     * This will load from a controller perspective the object
     * scope.
     *
     * @param scope
     */
    service.loadScope = (scope) => {
        service.scope = scope;
        return service;
    }

    /**
     * This will run when we click to add a new rule, also edit
     * as the scanStation.newRule is an object that stays after
     * any action, so this is to avoid to get data from a previous
     * utilization of the form.
     */
    service.cleanScopeNewRule = () => {
        if (service.scope.scanStation !== undefined &&
            service.scope.scanStation.newRule !== undefined &&
            service.scope.scanStation.newRule.rules_parameters !== undefined) {
            service.scope.scanStation.newRule.rules_parameters = undefined;
        }
    }

    /**
     * Sequence of steps to be able to eanble a rule.
     * @param ruleId
     */
    service.enableEditRule = (ruleId, ruleConditionId) => {
        try {

            service.scanStation.editRule = true;
            service.scanStation.editCurrentRuleId = ruleId;
            service.scanStation.newRuleID = ruleConditionId;

            service.scanStation.getRuleDetails();

            // cleaning the new rule parameter after click to enable edit
            service.cleanScopeNewRule();

        } catch (e) {
            service.scanStation.editRule = false;
            console.log(e);
        }

    }

    /**
     * Method that will disable the action of edit a rule.
     */
    service.disableEditRule = () => {
        try {

            service.scanStation.editRule = false;
            service.scanStation.editCurrentRuleId = undefined;
            service.scanStation.newRule.rules_parameters = {};
            service.scanStation.newRule.responseData = {};
            service.scanStation.newRule.customResponse = null;

        } catch (e) {
            service.scanStation.editRule = true;
            console.log(e);
        }

    }

    /**
     * This will return if we can edit or not, this is being used to differ all print.html elements
     * if this is true will be returning the case of edit, so it will be loading the field.html component
     * otherwise will be showing the text of the values inside of rules.rules_parameters.
     *
     * @param ruleId
     */
    service.canEditRule = (ruleId) => {
        try {

            let canEdit = false;
            if(service.scanStation.editRule === true
                && service.scanStation.editCurrentRuleId === ruleId){
                canEdit = true;
            }

            return canEdit;

        } catch (e) {
            service.scanStation.editRule = true;
            console.log(e);
        }

    }

    /**
     * This will be responsible for adding a new rule change select option,
     * this will clean anything that was messy on the scope and later on
     * load the rule details by using scanStation.newRuleID -> scanStation.rule_condition_id.
     *
     * Note: scanStation.newRuleID == scanStation.rule_condition_id.
     */
    service.addNewRuleChangeValueOnSelect = () => {
        try {
            // cleaning the new rule parameter after click to enable edit
            service.cleanScopeNewRule();
            service.scanStation.getRuleDetails();

        } catch (e) {

            console.log(e);
        }

    }

    return {
        loadScanStation : service.loadScanStation,
        loadScope : service.loadScope,
        cleanScopeNewRule : service.cleanScopeNewRule,
        enableEditRule : service.enableEditRule,
        disableEditRule : service.disableEditRule,
        canEditRule : service.canEditRule,
        addNewRuleChangeValueOnSelect : service.addNewRuleChangeValueOnSelect,
    }

}