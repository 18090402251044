import * as angular from 'angular';

"use strict";

angular
    .module('FieldModule')
    .factory('ExternalBlocDataFactory', ExternalBlocDataFactory);


ExternalBlocDataFactory.$inject = ['Configuration', '$http','ExternalBlocDataService'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function ExternalBlocDataFactory( Configuration, $http, ExternalBlocDataService) {
    let factory = this;

    /**
     * This function adds or remove the matching pairs of fields that are used to find out what to join in another bloc
     * @param localFieldMatchingFieldPairsArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveExternalDataBlocMatchingFieldPairs = (localFieldMatchingFieldPairsArray, action, localField, remoteField, index = null) => {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return localFieldMatchingFieldPairsArray;
        }

        if((remoteField == '' || typeof remoteField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return localFieldMatchingFieldPairsArray;
        }

        if(typeof localFieldMatchingFieldPairsArray == 'undefined') {
            localFieldMatchingFieldPairsArray = {};
        }

        if(action == 'add') {
            localField = JSON.parse(localField);
            remoteField = JSON.parse(remoteField);
            localFieldMatchingFieldPairsArray[Object.keys(localFieldMatchingFieldPairsArray).length] = {
                'localFieldId' : localField.id,
                'remoteFieldId' : remoteField.id
            };

        } else {
            delete localFieldMatchingFieldPairsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = localFieldMatchingFieldPairsArray;
            localFieldMatchingFieldPairsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                localFieldMatchingFieldPairsArray[i] = value;
                i++;
            });
        }

        return localFieldMatchingFieldPairsArray;

    }


    /**
     * This function adds to the fieldsThatTriggerExternalBlocData array, which is an array that contains the list of fields
     * that will trigger external data look ups
     * @param fieldIdToCheck
     * @param scanStationFields
     * @param fieldsThatTriggerExternalBlocData
     * @returns {any}
     */
    factory.getExternalBlocDataTriggeringFields = async (fieldIdToCheck, scanStationFields, fieldsThatTriggerExternalBlocData) => {


        for(let i=0;i<scanStationFields.length;i++) {

            if(scanStationFields[i].externalBlocDatalocalFieldMatchingFieldPairs != null ) {
                for(let in2=0;in2<scanStationFields[i].externalBlocDatalocalFieldMatchingFieldPairs.length;in2++) {
                    if(scanStationFields[i].externalBlocDatalocalFieldMatchingFieldPairs[in2].localFieldId == fieldIdToCheck) {
                        fieldsThatTriggerExternalBlocData.push(
                            {
                                fieldIdWithExternalBlocDataParameter : scanStationFields[i].id,
                                fieldIdThatTriggersLookUp : fieldIdToCheck
                            }
                        );
                    }
                }
            }
        }

        return fieldsThatTriggerExternalBlocData;
    }

    /**
     *
     * @param fieldId
     * @param fieldsThatTriggerExternalBlocData
     * @param dataToAdd
     * This function checks if the field we are working on is one that triggers an external bloc look up call that might be associated
     * with another field
     */
    factory.triggerExternalBlocLookUp = async (fieldId, fieldsThatTriggerExternalBlocData, dataToAdd) => {
        let matchedFieldIdsWithExternalBlocParameters = [];
        for(let i = 0;i<fieldsThatTriggerExternalBlocData.length;i++) {
            if(fieldsThatTriggerExternalBlocData[i]['fieldIdThatTriggersLookUp'] == fieldId) {
                matchedFieldIdsWithExternalBlocParameters.push(fieldsThatTriggerExternalBlocData[i]['fieldIdWithExternalBlocDataParameter']);
            }
        }
        if(matchedFieldIdsWithExternalBlocParameters.length>0) {
            return await ExternalBlocDataService.lookUpExternalBlocData(fieldId, matchedFieldIdsWithExternalBlocParameters, dataToAdd)
        } else {
            return false;
        }
    }

    return factory;
}

