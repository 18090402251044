import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('CustomerDetailsController', CustomerDetailsController);

// Injection of each class
CustomerDetailsController.$inject = ['$scope', 'ScopeAndLocal', 'Auth', '$window'];

// Controller Constructor
function CustomerDetailsController($scope, ScopeAndLocal, Auth, $window) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            vm.auth = Auth;
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Loading user details.
     *
     * @param field
     */
    vm.loadUserDetails = (field) => {

        if(vm.scanStation.scanStationObj.stateName ==  'edit-bloc') {
            return true;
        }

        if(field != null){
            vm.currentField = field;
        }

        vm.scanStation.domIndexes[vm.currentField.field_slug] = vm.currentField.fieldIndex;
        vm.scanStation.loggedInUsersName = vm.auth.userData.username;
        vm.scanStation.loggedInUsersEmail= vm.auth.userData.email

        // getting the correct value from the userData object.
        switch (field.userInfoRequired) {
            case 'full-name': // this will be for the name case
                vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.name;
                break;
            default : // this will be for the name case
                vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.email;
                break;
        }

        // at the end must check the rules for each vm.currentField.
        vm.scanStation.getRules('no-event',
            vm.currentField.id,
            vm.currentField.special_field_key,
            vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug],
            vm.currentField.fieldIndex,
            vm.currentField.field_slug
        );
    }

    /**
     * Here we reload the logged in username field from external controllers
     * TODO make the loading of logged in username data a service so different controllers can access it
     */
    $window.addEventListener('message', async function (data) {
        try {
            if (data.data && data.data.loggedinUserField != null ) {
                vm.loadUserDetails(data.data.loggedinUserField);
            }

        } catch (e) {
            console.log(e);
        }

    });

}

export default CustomerDetailsController;