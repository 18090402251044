import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('FieldActionsService', FieldActionsService);

/**
 * Service constructor.
 * @constructor
 */
function FieldActionsService() {
    let service = this;


    /**
     * This function provides the list of actions that happen when a field value changes, it considers the ones that happen before and after
     * a the enter key press. When customers set their owns for a field it overwrites this default one
     */
    service.getFieldActionsList = function (fieldObject, defaultFieldActionsList) {

        if (fieldObject.customFieldActionList != null) {
            return fieldObject.customFieldActionList;
        } else {
            return defaultFieldActionsList
        }
    }


}