import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('ExternalBlocDataService', ExternalBlocDataService);

/**
 * Service constructor.
 * @constructor
 */

ExternalBlocDataService.$inject = ['$http', 'Configuration'];


function ExternalBlocDataService($http, Configuration) {
    let service = this;

    /**
     * This calls the API to look up external data
     * @param gridId
     * @returns {*}
     */
    service.lookUpExternalBlocData = async function (fieldId, fieldIdsWithExternalBlocDataParameter, dataToAdd) {
        let apiBase = Configuration.getApiUrl() + 'look-up-external-bloc-data';
        let data = {
            fieldId: fieldId,
            fieldIdsWithExternalBlocDataParameter: fieldIdsWithExternalBlocDataParameter,
            dataToAdd: dataToAdd
        };
        return $http.post(apiBase, data);
    };
}