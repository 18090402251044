import * as angular from 'angular';

"use strict";

angular.module('DownloadModule')
    .controller('DownloadController', DownloadController);

DownloadController.$inject = ['$scope', 'DownloadService', 'DownloadFactory', 'TemplateService', 'scanStation'];

// Controller Constructor
function DownloadController($scope, DownloadService, DownloadFactory, TemplateService, scanStation) {

    let vm = this;
    vm.main = '';
    vm.scanStation = '';
    vm.data = '';
    vm.showTemplateBloc = null;
    vm.templateFileList = null;
    vm.downloadFactory = null;
    vm.downloadService = null;
    vm.exportFromData = null;
    vm.rowId = null;
    vm.rowKey = null;
    vm.psc = null;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {
            // Assigning the scan station object and main
            $scope.main = vm.main;
            $scope.scanStation = vm.scanStation;
            $scope.data = vm.data;
            $scope.exportFromData = vm.exportFromData;
            $scope.rowId = vm.rowId;
            $scope.rowKey = vm.rowKey;
            $scope.psc = vm.psc;

            vm.downloadFactory = DownloadFactory;
            vm.downloadService = DownloadService;
            vm.templateService = TemplateService;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load all scan station template elements
     * for each scan station.
     *
     * @param scanStationId
     */
    vm.getScanStationTemplates = async (scanStationId) => {
        try {

            if (vm.templateFileList === null) {
                vm.templateFileList = await vm.templateService.loadScanStationTemplates(scanStationId);
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will download a template.
     */
    vm.templateDownload = async (scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord, rowId = null, emailAddresses = null) => {
        try {

            vm.waitingForTemplate = true;
            try {
                if(chosenTemplateFile == 'automatically-chosen-template') {
                    let response = await vm.downloadService.getAutomaticallyChosenTemplateFile(scanStationId, dataToAdd, rowId);
                    chosenTemplateFile = response.data.templateFile.exported_filename;

                    vm.setSelectedDownloadDetails(response.data.templateFile);
                }

                let response = await vm.downloadService.templateDownload(scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord, rowId, emailAddresses);
                if (emailAddresses == null) {
                    let blob = new Blob([response.data], {
                        type: 'application/octet-stream'
                    });

                    let URL = window.URL || window.webkitURL;

                    vm.downloadFactory.downloadUrl = URL.createObjectURL(blob);

                    if (pdfOrWord != 'pdf' && vm.selectedFileOutputFileName == null && chosenTemplateFile != 'automatically-chosen-template') {
                        vm.downloadFactory.filename = vm.scanStation.scanStationObj.stationName + '.' + chosenTemplateFile.split('.').pop();
                    } else if (pdfOrWord != 'pdf' && vm.selectedFileOutputFileName != null  && chosenTemplateFile != 'automatically-chosen-template') {
                        vm.downloadFactory.filename = vm.selectedFileOutputFileName + '.' + chosenTemplateFile.split('.').pop();
                    } else if (pdfOrWord == 'pdf' && vm.selectedFileOutputFileName != null) {
                        vm.downloadFactory.filename = vm.selectedFileOutputFileName + '.pdf';
                    } else {
                        vm.downloadFactory.filename = vm.scanStation.scanStationObj.stationName + '.pdf';
                    }

                    let element = await document.getElementById('downloadPdfBtn');
                    vm.waitingForTemplate = false;

                    $scope.$apply();

                    if(this.psc != null) {
                        this.psc.showPDFEXport = false;
                    }

                    element.click();
                } else {
                    vm.waitingForTemplate = false;
                    $scope.$apply();
                    alert('Attachment has been successfully sent.');

                }
            } catch (err) {
                alert('Something has gone wrong with this export');
                vm.waitingForTemplate = false;
                $scope.$apply();
                console.log(err);
                return false;
            }


        } catch (e) {
            console.log(e);
        }
    }

    vm.buildMailToAddresses = async (source) => {
        try {
            vm.emailAddresses = '';

            let dataSource = vm.scanStation.scanStationObj.dataToAdd;

            if (source == 'in-data') {
                dataSource = vm.scanStation.scanStationObj.liveData[vm.rowKey];
            }

            vm.emailAddresses = await DownloadFactory.buildMailToAddresses(dataSource, vm.scanStation.scanStationObj.scanStationFields);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function sets up the details for the file we have chosen to download
     * @param templateFile
     */
    vm.setSelectedDownloadDetails = async (templateFile) => {
        vm.selectedFileToDownload = templateFile.exported_filename;
        if (templateFile.output_filename == null || templateFile.output_filename == '') {
            vm.selectedFileOutputFileName = null;
        } else {
            vm.selectedFileOutputFileName = templateFile.output_filename;

            // replace the [BW]field_slug[/BW] in the file name with data
            let dataObject = [];

            // we check if we are exporting from the top of the bloc, or from inside the results
            // this can be checked by seeing if the rowId exists in the component or not
            if (vm.rowId == null) {
                dataObject = Object.entries(vm.scanStation.scanStationObj.dataToAdd);
            } else if (vm.rowId != null && vm.rowKey != null) {
                let singleRecordObject = await scanStation.getSingleRecordByRecordId(vm.rowId);
                dataObject = Object.entries(singleRecordObject.data.data);

            }

            for (let [index, val] of dataObject) {
                vm.selectedFileOutputFileName = vm.selectedFileOutputFileName.replaceAll(`[BW]${index}[/BW]`, val);
            }

        }

    }
}

export default DownloadController;