import * as angular from 'angular';
import NewRule from "../model/newRule";

"use strict";

angular.module('FieldModule')
    .controller('ScanStationFieldRulesController', ScanStationFieldRulesController);

ScanStationFieldRulesController.$inject = ['$scope', 'fieldRuleFactory', 'rulesFactory', 'ScopeAndLocal', 'fieldRuleService'];

// Controller Constructor
function ScanStationFieldRulesController($scope, fieldRuleFactory, rulesFactory, ScopeAndLocal, fieldRuleService) {

    let vm = this;
    vm.scanStation = '';
    vm.main = '';
    vm.rules = '';
    vm.rulesFactory = ''

    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.rulesFactory = rulesFactory;
            rulesFactory.loadScanStation(vm.scanStation);

            vm.rules = vm.loadData();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will get the data from the scanStation fieldRules.
     */
    vm.loadData = () => {
        return vm.scanStation.fieldRules;
    }

    /**
     * This will check if is valid the added rule.
     * @param field_id
     */
    vm.validateAddedRules = ({field_id}) => {
        return field_id === vm.scanStation.fieldIDForUpdatingRules;
    }

    /**
     * This will clear unwanted rule parameters.
     */
    vm.clearUnwantedRuleParameters = async function () {

        // TODO: remove the newRule from the scanStation and change to rules_parameters
        vm.scanStation.newRule.rules_parameters = await fieldRuleFactory.clearUnwantedRuleParameters(vm.scanStation.newRuleID, vm.scanStation.newRule.rules_parameters);
    }

    /**
     * This will update a scan station field rule data.
     */
    vm.updateScanStationRules = async function (ruleId) {

        try {
            await vm.clearUnwantedRuleParameters();
            vm.scanStation.editNewRuleCase = true;
            await vm.scanStation.editNewRule(ruleId);
            vm.scanStation.showResponseDataAndResponseTypes = 0;
            vm.scanStation.scrollTo('add-rules', '.field-rules', true);
            vm.scanStation.editRule = false;
            $scope.$apply();

        } catch (e) {
            vm.scanStation.editRule = true;
            console.log(e);
        } finally {
            vm.scanStation.editNewRuleCase = false;
        }

    }

    /**
     * Method that gets the things to confirm before submit a new rule,
     * this was created to remove the issue with order from the frontend,
     * as we have many async and await functions.
     *
     * @param scanStation
     */
    vm.submitRule = async (scanStation) => {
        try {
            await vm.clearUnwantedRuleParameters();
            await scanStation.submitNewRule();
            scanStation.showResponseDataAndResponseTypes = 0;
        } catch (e) {
            vm.scanStation.editRule = true;
            console.log(e);
        }

    }

    /**
     * This will be loading teh rules parameters for the
     * edit case.
     *
     * @param rule
     */
    vm.loadEditCase = async function (rule) {
        try {

            // loading the value for rule_parameters
            let response = await fieldRuleService.loadScanStationFieldRule(rule.id, rule.scan_station_id, rule.field_id);
            let ruleData = response.data.rule;
            vm.scanStation.selectedRuleID = rule.rule_condition_id;

            let responseJson = JSON.parse(ruleData.response_data)
            vm.scanStation.showResponseDataAndResponseTypes =
                responseJson != null && Object.keys(responseJson).length > 1 ? 1 : 0

            let newRuleData = new NewRule().build(ruleData);

            vm.scanStation.newRule = newRuleData;
            vm.scanStation.newRule.rules_parameters = newRuleData.rulesParameters;
            vm.scanStation.newRuleID = vm.selectedOption = rule.rule_condition_id;
            vm.scanStation.fieldRuleId = rule.id;

            if (vm.scanStation.ruleData[rule.rule_condition_id].load_scan_station_fields_param != null) {
                vm.loadStationFieldsForRule(vm.scanStation.ruleData[rule.rule_condition_id].load_scan_station_fields_param);
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.loadEditRule = async function (rule) {
        try
        {
            // as this isnt aync, we do not need to add the await
            vm.rulesFactory.enableEditRule(rule.id, rule.rule_condition_id);

            // the edit case we do a request on the fieldRuleService, so we have to await for that
            await vm.loadEditCase(rule);

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be running before any field Rule that are required to
     * have the scanStationFieldsForRule in field.html.
     */

    vm.loadStationFieldsForRule = async (paramToLoad) => {

        vm.scanStation.getJobScanStations(null);
        await vm.scanStation.getStationStationFieldsForRule(vm.scanStation.newRule.rules_parameters[paramToLoad]);
        $scope.$apply();

    }

}

export default ScanStationFieldRulesController;