// Loading all controllers

import './blocworx/controllers/bootstrap.controller'

// Calendar Module Controllers
import './calendar/controllers/calendar.controller'

// Sound controller
import './sound/controllers/sound.controller'

// Datetime controllers
import './datetime/controllers/datetime.controller'

// Field Module Controllers
import './field/controllers/field.controller'
import './field/controllers/pieChart.controller'
import './field/controllers/eChart.controller'
import './field/controllers/crossBlocDataUpdates.controller'
import './field-rule/controllers/scanStation.field.rules.controller'
import './field-rule/controllers/fieldRule.controller'
import './field-rule/controllers/printRule.controller'

// Grid Module Controllers
import './grid/controllers/grid.controller'

// ScanStation Module Controllers
import './scanstation/controllers/scanstation.field.controller'
import './scanstation/controllers/scanstation.header.controller'

// Icon module Controller
import './icon/controllers/icon.controller'

// Page module Controller
import './page/controllers/page.controller'

// Sidebar module Controller
import './sidebar-pages/controllers/sidebar-pages.controller'

// Page bar module controller
import './page-bar/controllers/pagebar.controller'

// Breadcrumb module controller
import './breadcrumb/controllers/breadcrumb.controller'

// Back Button module controller
import './back-button/controllers/back-button.controller'

// Help Mode module Controller
import './help-mode/controllers/helpmode.controller'

// Template module controller
import './template/controllers/template.controller'

// Roles and Permissions module controller
import './roles-and-permissions/controllers/roles-and-permissions.controller'

// Job module controller
import './module/controllers/module.controller'

// Qr controller
import './field/components/qrCode/controllers/qrCode.controller'

// Mindee controller
import './field/components/mindee/controllers/mindee.controller'

// Sub Bloc controller
import './field/components/subbloc/controllers/subbloc.controller'

// Scan Station Component Controlles
import './scan-station-components/count/controllers/count.controller'

// Barcode Generator
import './field/components/barcodeGenerator/controllers/barcodeGenerator.controller'

// sockets Controllers
import './sockets/components/getLatestData/controllers/get-latest-data.controller'

// url Controller
import './url/controllers/url.controller'