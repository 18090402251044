import * as angular from 'angular';

"use strict";

angular.module('ExportModule')
       .service('exportService', ExportService)
       .config(ExportServiceConfig);

ExportService.$inject = ['$http', 'Configuration'];

/**
 * Service constructor.
 * @constructor
 */
function ExportService($http, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    service.getCartolyticsCustomersIDs = async () => {
        let apiBase = apiUrl + 'get-cartolytics-customers-ids';
        let cartolyticsCustomersIDs = [];
        let config = {
            params: {}
        };

        let returnedData = await $http.get(apiBase, config);
        if(returnedData !== undefined){
            cartolyticsCustomersIDs = returnedData.data.data;
        }

        return cartolyticsCustomersIDs;
    }

    /**
     * This will be responsible to schedule a database dump
     * @param cartolyticsCustomerId
     */
    service.scheduleDatabaseDump = async (cartolyticsCustomerId) => {

        let apiBase = apiUrl + 'schedule-database-dump';
        let data = {
            cartolyticsCustomerId : cartolyticsCustomerId
        };
        let returnedData = await $http.post(apiBase, data);
        return returnedData;
    }

    /**
     * This will be responsible to schedule a database dump
     * @param cartolyticsCustomerId
     */
    service.deleteScheduleDatabaseDump = async (id) => {

        let apiBase = apiUrl + 'delete-schedule-database-dump';
        let data = {
            id : id
        };
        let returnedData = await $http.post(apiBase, data);
        return returnedData;
    }

    /**
     * This will return all the scheduled data.
     *
     * @param cartolyticsCustomerId
     */
    service.loadScheduledData = async (cartolyticsCustomerId) => {

        let apiBase = apiUrl + 'load-scheduled-data';
        let data = {
            params: {
                cartolyticsCustomerId : cartolyticsCustomerId
            }
        };
        let returnedData = await $http.get(apiBase, data);
        return returnedData;
    }

    service.loadAllScheduledData = async (cartolyticsCustomerId) => {

        let apiBase = apiUrl + 'load-all-scheduled-data';
        let data = {
            params: {
                cartolyticsCustomerId : cartolyticsCustomerId
            }
        };
        let returnedData = await $http.get(apiBase, data);
        return returnedData;
    }
}


function ExportServiceConfig ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}