import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ExternalBlocDataController from "./controllers/external-bloc-data.controller";

"use strict";

class ExternalBlocDataComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/external-bloc-data';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        data: '=',
        main: '='
    }

    // This will set what will be the controller of this component
    public controller : any = ExternalBlocDataController;

    // This will set an alias
    public controllerAs: string = 'ebd';
}

angular.module('FieldModule').component('externalBlocData', <StandardComponent> new ExternalBlocDataComponent);