import * as angular from 'angular';

'use strict';

angular.module('FieldModule')
    .factory('fieldRuleService', FieldRuleService)
    .config(FieldRuleServiceConfig);

FieldRuleService.$inject = ['$http', 'Configuration'];

function FieldRuleService($http, Configuration) {

    let factory = this;
    let apiUrl = Configuration.getApiUrl();

    factory.loadScanStationFieldRule = (ruleId, scanstationID, fieldId) => {
        let apiBase = apiUrl + 'load-scan-station-field-rule';
        let config = {
            scanStationID: scanstationID,
            fieldId: fieldId,
            id : ruleId
        };
        return $http.post(apiBase, config);
    }

    return {
        loadScanStationFieldRule : factory.loadScanStationFieldRule,
    }

}

function FieldRuleServiceConfig ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}
