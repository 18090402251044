import * as angular from 'angular';
import * as $ from "jquery";
import list from "postcss/lib/list";
import ScanStationController from "components/blocworx/components/scanStation/controllers/scanstation.controller";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('FieldActionsController', FieldActionsController);

// Injection of each class
FieldActionsController.$inject = [
    '$rootScope',
    '$scope',
    'ScopeAndLocal',
    'sound',
    'status',
    'Blocworx',
    'FieldActionsFactory',
    '$window',
    'MindeeFactory',
    'LinkFieldFactory',
    'BlocworxGatewayFactory',
    'SubBlocFieldFactory',
    'scanStation',
    'DataFromStationFieldsFactory',
    'FieldActionsService',
    '$mdDialog',
    'ExternalBlocDataFactory',
    'ResultsBoxService'
];

// Controller Constructor
function FieldActionsController($rootScope,
                                $scope,
                                ScopeAndLocal,
                                sound,
                                status,
                                Blocworx,
                                FieldActionsFactory,
                                $window,
                                MindeeFactory,
                                LinkFieldFactory,
                                BlocworxGatewayFactory,
                                SubBlocFieldFactory,
                                scanStation,
                                DataFromStationFieldsFactory,
                                FieldActionsService,
                                $mdDialog,
                                ExternalBlocDataFactory,
                                ResultsBoxService) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            /**
             * This is the master field actions function, this runs every time a value changes in a field
             * @param e
             * @param fieldId
             * @param special_field_key
             * @param data
             * @param index
             * @param fieldSlug
             * @returns {Promise<boolean>}
             */
            vm.scanStation.getRules = async function (e, fieldId, special_field_key, data, index, fieldSlug) {
                try {

                    if(vm.scanStation.scanStationObj.stateName == 'edit-bloc') {
                        return true;
                    }

                    // ng-keydown is causing our value to be 1 entry behind when we are not hitting enter, so field parameters like trigger rules on key press arent working properly
                    // for now we must for a $scope.$apply() to ensure we are working with the latest data value

                    await vm.scanStation.updateDataValue();
                    $scope.$apply();
                    data = vm.scanStation.scanStationObj.dataToAdd[fieldSlug];

                    // the following must always happen regardless of any patterns set

                    $('#station-field-' + index).removeClass('minor-issue');

                    // TODO: this uses jquery, could be nice to use the object to check this out

                    vm.scanStation.updateLastField(fieldId);

                    // TODO: why we have a success message before finish all the things?
                    // Answer: The function name was bad, it should have been hideDataAddSuccessMsg() not showDataAddSuccessMsg
                    if (vm.scanStation.dataAddSuccess == 1) {
                        await vm.scanStation.hideDataAddSuccessMsg();
                    }

                    // we may prevent actions from occuring automatically if you have just clicked edit or show more,
                    // however if someone has done a key press, click or changed a select dropdown then we always enable actions
                    if(e != 'no-event') {
                        vm.scanStation.allowActionsAfterShowMore = true;
                        vm.scanStation.allowActionsAfterEditClick = true;
                    }


                    // contains the various ways we decide not to carry on
                    let stopNow = await FieldActionsFactory.preventActionsFromProceeding(vm.scanStation, fieldId, vm.scanStation.scanStationObj.scanStationFieldIdsToField, e, data, vm.scanStation.preventRuleCall, fieldSlug);

                    if (stopNow == true) {
                        return true;
                    }

                    // we use this state to check if this is being fired while filling the form or after the form as been submitted.
                    let afterSubmitState = false;

                    if (e == 'after-submission') {
                        afterSubmitState = true;
                    }

                    let fieldObject = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId];
                    // finally we are ready to trigger all actions set before the key press
                    await vm.triggerTheActions(fieldObject, fieldId, 'before_keypress', fieldSlug, special_field_key, index, data, afterSubmitState, e);

                    // we only want to kill the prompts if a key has been pressed, otherwise we lose potentially lose the error message immediately
                    // also we now want to pretend its a keypress so we can continue onwards
                    if (e == 'no-event' || e == 'after-submission') {
                        e = {};
                        e.keyCode = 13;
                    } else {
                        vm.scanStation.prompt = false;
                        vm.scanStation.yellowPrompt = false;
                    }


                    // TODO: refactor to key action, will trigger validations
                    if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'click' || e === 'select-change' || e === 'force-rule' || vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].triggerRuleOnValueChange == 1) {

                        // finally we are ready to trigger all actions set after the key press
                        await vm.triggerTheActions(fieldObject, fieldId, 'after_keypress', fieldSlug, special_field_key, index, data, afterSubmitState, e);

                        $scope.$apply();
                    }

                    $scope.$apply();
                    return true;


                } catch (e) {
                    console.log(e);
                }
            };


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function loops through the designated actions to carry out for a field before or after the key press
     * @param beforeOrAfterKeyPress
     */

    vm.triggerTheActions = async (fieldObject, fieldId, beforeOrAfterKeyPress, fieldSlug, special_field_key, index, data, afterSubmitState, e) => {

        // TODO figure out why a null value is being passed in here

        if(fieldObject == null || vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId] == null) {
            return;
        }

        let listOfActions = await FieldActionsService.getFieldActionsList(fieldObject, vm.scanStation.scanStationObj.defaultFieldActionsList);
        if (listOfActions == false) {
            listOfActions = vm.scanStation.scanStationObj.defaultFieldActionsList;
        }

        for(let actionItemCounter=0;actionItemCounter<vm.ensureArray(listOfActions[beforeOrAfterKeyPress]).length;actionItemCounter++) {
            let actionItem = listOfActions[beforeOrAfterKeyPress][actionItemCounter];

            switch (actionItem.action) {
                case 'generate_file_for_bartender':
                    await vm.scanStation.generateFileForBartender(fieldSlug);
                    break;
                case 'build_merge_fields':
                    vm.scanStation.detectFieldAndBuildMerge(fieldSlug);
                    break;
                case 'rebuild_linked_data_json':
                    vm.scanStation.linkedDataFieldJson = await LinkFieldFactory.rebuildLinkedDataJson(vm.scanStation.scanStationObj.linkDataFields, vm.scanStation.scanStationObj.scanStationFieldIdsToField, vm.scanStation.scanStationObj.dataToAdd);
                    $scope.$apply();
                    break;
                case 'apply_conditional_values':
                    await vm.scanStation.parseAndGateParam(fieldId, special_field_key, index);
                    break;
                case 'check_show_counter':
                    await vm.scanStation.checkShowCounter(fieldSlug);
                    break;
                case 'reset_autocomplete':
                    //await vm.scanStation.checkShowCounter(fieldSlug);
                    break;
                case 'update_mindee_single_or_bulk_options':

                    if(vm.scanStation.scanStationObj.mindeeFields.length > 0) {
                        await MindeeFactory.updateBulkCases(vm.scanStation.scanStationObj.mindeeFields);
                    }

                    break;
                case 'apply_external_bloc_data_lookup':

                    /** This case checks if the field triggers an external bloc data parameter set up in another field */
                    let externalBlocResponse = await ExternalBlocDataFactory.triggerExternalBlocLookUp(fieldId, vm.scanStation.fieldsThatTriggerExternalBlocData, vm.scanStation.scanStationObj.dataToAdd);

                    if(externalBlocResponse != false && externalBlocResponse.data.fieldIdsWithExternalBlocDataParameter != null) {

                        // we need to populate the data and call rules for each field that has a result
                        for(let i = 0;i<externalBlocResponse.data.fieldIdsWithExternalBlocDataParameter.length;i++) {

                            let fieldIdWithExternalBlocDataParameter = externalBlocResponse.data.fieldIdsWithExternalBlocDataParameter[i];

                            let fieldWithExternalBlocDataParameter = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldIdWithExternalBlocDataParameter];

                            if (fieldWithExternalBlocDataParameter != null && externalBlocResponse.data.data != null) {

                                let fieldWithExternalBlocDataParameterSlug = fieldWithExternalBlocDataParameter.field_slug;

                                if (externalBlocResponse.data.data[fieldWithExternalBlocDataParameterSlug] != null) {
                                    vm.scanStation.scanStationObj.dataToAdd[fieldWithExternalBlocDataParameterSlug] = externalBlocResponse.data.data[fieldWithExternalBlocDataParameterSlug];
                                    await vm.scanStation.getRules('no-event', fieldWithExternalBlocDataParameter.id, fieldWithExternalBlocDataParameter.special_field_key, externalBlocResponse.data.data[fieldSlug], fieldWithExternalBlocDataParameter.fieldIndex, fieldWithExternalBlocDataParameter.field_slug);
                                    $scope.$apply();

                                }
                            }
                        }
                    }

                    break;
                case 'apply_date_field_changes':
                    await vm.scanStation.updateDateFieldsWithNewValues(fieldSlug, data);
                    break;
                case 'trigger_blocworx_gateway_call':
                    if(vm.scanStation.scanStationObj.blocworxGatewayFields.length > 0) {
                        const gatewayFieldId = await BlocworxGatewayFactory.shouldTriggerActionsFromGetRules(fieldId, vm.scanStation.scanStationObj.scanStationFieldIdsToField);
                        if (gatewayFieldId) {
                            const response = await BlocworxGatewayFactory.triggerActions(gatewayFieldId, vm.scanStation.scanStationObj.dataToAdd, vm.scanStation.scanStationObj.filesToAdd);
                            $scope.$broadcast('triggerPostBlocworxGatewayCall', {
                                fieldId: gatewayFieldId,
                                response: response
                            });
                        }
                    }
                    break;
                case 'force_numeric_field_options':
                    await vm.scanStation.setNumericField(fieldId, fieldSlug, data, index);
                    break;
                case 'strip_special_characters_from_string':
                    if(vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].stripSpecialCharacters == 1) {
                        let strippedString = await scanStation.stripSpecialCharacters(data);
                        vm.scanStation.scanStationObj.dataToAdd[fieldSlug] = strippedString.data.data;
                        data = strippedString.data.data;
                    }
                    break;
                case 'send_data_from_parent_to_child_subbloc':
                    // factory step: vm.scanStation.subformArray
                    angular.forEach(vm.scanStation.subformArray, function (subFormDatum, subFormSlug) {
                        let fieldSlugToUpdate = null;
                        let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[subFormSlug];
                        angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                            if (fieldId == parentChildCombo.parentFieldId) {
                                vm.scanStation.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug);
                            }
                        });
                    });
                    break;
                case 'send_data_from_child_to_parent_bloc':
                    if(Object.keys(vm.scanStation.subformArray).length>0) {
                        await SubBlocFieldFactory.sendDataToParent(data, vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId]);
                    }
                    break;
                case 'trigger_chart_updates':
                    await vm.scanStation.triggerChartUpdates(fieldSlug);
                    break;
                case 'apply_field_filtering':
                    await vm.scanStation.isFieldAsFilterCase(fieldId, data);
                    break;
                case 'apply_data_to_form_fields':
                    angular.forEach(vm.scanStation.dataToFormArray, async function (dataToFormField, dataToFormIndex) {
                        if (typeof data != 'undefined') {

                            // Field IDs can sometimes be integers and sometimes be strings

                            if (dataToFormField.localFormListFieldsForPassingData.includes(fieldId) || dataToFormField.localFormListFieldsForPassingData.includes(Number.parseInt(fieldId))) {
                                if (typeof dataToFormField.dataToFormFieldSlug != 'undefined') {
                                    await vm.scanStation.buildDataToFormsJSONData(dataToFormField.dataToFormFieldSlug);
                                }
                            }

                            if (typeof (dataToFormField.jobID) == 'undefined') {
                                dataToFormField.jobID = vm.scanStation.jobID;
                            }

                            if (fieldId == dataToFormField.localLinkedStationFieldId && typeof dataToFormField.linkedStationDetails != 'undefined' && Object.keys(dataToFormField.linkedStationDetails).length > 0 && data != '') {
                                await vm.scanStation.dataToFormCheckLinkedStation(data, dataToFormField.linkedStationDetails.remoteFieldToSearchId,
                                    dataToFormField.linkedStationDetails.remoteFieldWithBlocNamesId, dataToFormField.linkedStationDetails.stationId,
                                    dataToFormField.dataToFormFieldSlug, dataToFormField.jobID);
                            }
                        }
                    });
                    angular.forEach(vm.scanStation.scanStationObj.dataToFormLatestDataFilters, function (dataToFormFilters, dataToFormFieldSlug) {
                        angular.forEach(dataToFormFilters, async function (dataToFormFilterFieldSlug) {
                            if (fieldSlug == dataToFormFilterFieldSlug) {
                                await vm.scanStation.buildBlocListLatestData(dataToFormFieldSlug);
                                $scope.$apply();
                            }
                        });
                    });
                    break;
                case 'apply_time_difference_field_calculations':
                    if (vm.scanStation.timeDiffFields) {
                        //TODO check for fields that are startFieldSlug or finishFieldSlug then calculate difference
                        angular.forEach(vm.scanStation.timeDiffFields, function (timeDiffField) {
                            if (timeDiffField.startFieldSlug == fieldSlug || timeDiffField.finishFieldSlug == fieldSlug) {
                                vm.scanStation.calculateTimeDifference(timeDiffField.timeDiffFieldSlug, timeDiffField.field, timeDiffField);
                            }
                        });
                    }
                    break;
                case 'update_results_boxes':
                    if(vm.scanStation.resultsBoxFieldsThatTrigger != null && vm.scanStation.resultsBoxFieldsThatTrigger[fieldSlug] != null) {
                        await ResultsBoxService.callTriggerResultsBoxFromFieldActions(fieldSlug);
                    }
                    break;
                case 'get_dropdown_lists_from_other_blocs':
                    // a change in local field that is used to match data can trigger the dropdown look up rule

                    let canGetLocalFieldMatchingFieldPairs = await DataFromStationFieldsFactory.canGetLocalFieldMatchingFieldPairs(fieldId, vm.scanStation.scanStationObj.dropdownFilteredDataFields);
                    if (canGetLocalFieldMatchingFieldPairs.length > 0) {

                        angular.forEach(canGetLocalFieldMatchingFieldPairs, async function (value) {
                            angular.forEach(value.localFieldMatchingFieldPairs, function (fieldPair) {
                                fieldPair.localFieldValue = vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                            });

                            angular.forEach(value.localFieldExclusionMatchingFieldPairsArray, function (fieldPair) {
                                fieldPair.localFieldValue = vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                            });

                            await vm.scanStation.getStationDataForDropDown(value.fieldListSlug, value.dropdownFromStationLookUp, value.dropdownFromStationOnlyDataAfterDate, value.localFieldMatchingFieldPairs, value.dropdownFromStationReverseOrder, value.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, value.localFieldExclusionMatchingFieldPairsArray, value.dropdownFromStationOrder);

                        });
                    }

                    // a change in the local field that is used to exclude data can also trigger the rule

                    let canGetLocalFieldExclusionMatchingFieldPairs = await DataFromStationFieldsFactory.canGetLocalFieldExclusionMatchingFieldPairs(fieldId, vm.scanStation.scanStationObj.dropdownFilteredDataFields);
                    if (canGetLocalFieldExclusionMatchingFieldPairs.length > 0) {

                        angular.forEach(canGetLocalFieldExclusionMatchingFieldPairs, async function (value) {
                            angular.forEach(value.localFieldMatchingFieldPairs, function (fieldPair) {
                                fieldPair.localFieldValue = vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                            });

                            angular.forEach(value.localFieldExclusionMatchingFieldPairsArray, function (fieldPair) {
                                fieldPair.localFieldValue = vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                            });

                            await vm.scanStation.getStationDataForDropDown(value.fieldListSlug, value.dropdownFromStationLookUp, value.dropdownFromStationOnlyDataAfterDate, value.localFieldMatchingFieldPairs, value.dropdownFromStationReverseOrder, value.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, value.localFieldExclusionMatchingFieldPairsArray);
                            $scope.$apply();

                        });
                    }
                    break;
                case 'update_all_maths_fields':
                    await vm.scanStation.updateLocalMathsResults(fieldSlug);
                    break;
                case 'apply_new_autogenerate_values':

                    // We have a parameter that allows someone to retrigger the autogenerate even when we are editing data and it appears due to a Conditional Visibility

                    let retriggerDuringEditingOnConditionalVisibility = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].retriggerDuringEditingOnConditionalVisibility;

                    if(!vm.scanStation.scanStationObj.isInEditMode || retriggerDuringEditingOnConditionalVisibility == 1) {
                        angular.forEach(vm.scanStation.scanStationObj.autogenerateTriggerFields, async function (autoGenerateReferenceFieldArray, autoGenerateFieldSlug) {
                            for(let i=0;i<autoGenerateReferenceFieldArray.length;i++) {
                                if (autoGenerateReferenceFieldArray[i][0] == fieldSlug) {
                                    await vm.scanStation.getAutoGenerateFieldData(autoGenerateReferenceFieldArray[i][1], autoGenerateReferenceFieldArray[i][2], autoGenerateFieldSlug);
                                }
                            }
                        });
                    }
                    break;
                case 'call_rules':
                    if (typeof (vm.scanStation.scanStationObj.liveData.countLimitInfo) != 'undefined'
                        && vm.scanStation.scanStationObj.liveData.countLimitInfo.limit_reached == 1) {
                        vm.scanStation.prompt = 'Scan Limit has been reached';
                        await vm.scanStation.triggerPrompt(index);
                    } else {

                        try {

                            // we don't need to call rules if the field doesnt have any
                            if(!vm.scanStation.fieldIdsWithRules.includes(fieldId)) {
                                await vm.triggerAutomaticSubmission(e, fieldId);
                                break;
                            }

                            // we dont want to go any further if we meet this criteria as we have all actions triggered that we needed
                            if (afterSubmitState == true && vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].preventBackEndRulesFromFiring == 1) {
                                return true;
                            }

                            let allFieldsData = vm.scanStation.getAllFieldsData();

                            // todo: check DATA here!
                            let ruleResponse = await scanStation.getRules(fieldId, data, vm.scanStation.scanStationObj.stationDataID, allFieldsData);

                            if (typeof (ruleResponse.data.metaData.populateOtherFieldDataFromRulePassing) != 'undefined') {
                                angular.forEach(ruleResponse.data.metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                                    if (typeof vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != 'undefined') {
                                        vm.scanStation.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        if (vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                            let field = vm.scanStation.scanStationObj.scanStationFieldIdsToField[index];
                                            vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                        }
                                    }
                                });
                            }

                            // TODO is this is ever being used?
                            angular.forEach(ruleResponse.data.metaData.responseData.populate_fields, function (value, index) {
                                vm.scanStation.dataToAdd[index] = value;
                                vm.scanStation.scanStationObj.dataToAdd[index] = value;
                                if (vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                    let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index];
                                    vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                }

                            });

                            await vm.triggerAutomaticSubmission(e, fieldId);

                            $scope.$apply();

                        } catch (res) {

                            let metaData = res.data.metaData;
                            let responseData = res.data.responseData;
                            let failedFieldRule = res.data.failedFieldRule;
                            let failedResponseType = res.data.failedResponseType;

                            // This will populate when we have failures
                            if (metaData !== undefined && metaData.populateOtherFieldDataFromRuleFailure != 'undefined') {
                                angular.forEach(metaData.populateOtherFieldDataFromRuleFailure, function (value, index) {
                                    if (typeof vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != 'undefined') {
                                        vm.scanStation.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        if (vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                            let field = vm.scanStation.scanStationObj.scanStationFieldIdsToField[index];
                                            vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                        }
                                    }
                                });
                            }

                            // This will populate when we have success case
                            if (metaData !== undefined && metaData.populateOtherFieldDataFromRulePassing != 'undefined') {
                                angular.forEach(metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                                    if (typeof vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != 'undefined') {
                                        vm.scanStation.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                                        if (vm.scanStation.scanStationObj.scanStationFieldIdsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldIdsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                            let field = vm.scanStation.scanStationObj.scanStationFieldIdsToField[index];
                                            vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                        }
                                    }
                                });
                            }

                            // TODO is this being used?

                            if (responseData !== undefined && responseData.populate_fields != 'undefined') {
                                angular.forEach(responseData.populate_fields, function (value, index) {
                                    vm.scanStation.dataToAdd[index] = value;
                                    vm.scanStation.scanStationObj.dataToAdd[index] = value;
                                    if (vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                        let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index];
                                        vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                    }
                                });
                            }

                            if (responseData !== undefined && responseData.populate_fields != 'undefined') {

                                // TODO is this being used? We dont send back field slugs anymore
                                angular.forEach(res.data.responseData.populate_fields, function (value, index) {
                                    if (value != null && value != '') {
                                        vm.scanStation.dataToAdd[index] = value;
                                        vm.scanStation.scanStationObj.dataToAdd[index] = value;
                                        if (vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                            let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index];
                                            vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                        }
                                    }
                                    if (typeof (vm.scanStation.scanStationObj.dataToAdd[index]) != 'undefined') {
                                        if (value != null && value != '') {
                                            vm.scanStation.dataToAdd[index] = value;
                                            vm.scanStation.scanStationObj.dataToAdd[index] = value;
                                            if (vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                                let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index];
                                                vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                            }
                                        }
                                    } else {
                                        angular.forEach(vm.scanStation.scanStationObj.scanStationFields, function (fieldValue) {
                                            if (fieldValue.field_slug == index) {
                                                vm.scanStation.hiddenDataToPopulate[index] = value;
                                                if (value != null && value != '') {
                                                    vm.scanStation.dataToAdd[index] = value;
                                                    vm.scanStation.scanStationObj.dataToAdd[index] = value;
                                                    if (vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index] != null && vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index].forceTriggerOnValueChangeFromElsewhere == 1) {
                                                        let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[index];
                                                        vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.fieldIndex, field.field_slug);
                                                    }
                                                }
                                            }

                                        });
                                    }
                                });
                            }

                            if (failedResponseType !== null) {
                                if (failedResponseType == 'Red Warning Reject') {
                                    vm.scanStation.prompt = res.data.error;
                                    await vm.scanStation.triggerPrompt(index);
                                } else {
                                    if (failedResponseType == 'Yellow Warning Accept') {
                                        vm.scanStation.yellowPrompt = res.data.error;
                                        await vm.scanStation.triggerYellowPrompt(index, true);
                                    }
                                }
                            }

                        $scope.$apply();

                        }
                    }
                    break;
                case 'apply_totals_fields':
                    if (vm.scanStation.totalsField && vm.scanStation.scanStationObj.isInEditMode == false) {
                        angular.forEach(vm.scanStation.totalsField, async function (totalField) {
                            if (totalField.parentField == fieldSlug) {
                                await vm.scanStation.getTotals(totalField.totalsField, totalField.totalResultsAssociatedFields, data);
                            }

                            if (vm.scanStation.showFormceptionPopup) {
                                await vm.scanStation.getTotalsResults();
                            }
                        });
                    }

                    break;
                default:
                // code block
            }

        }

    }

    vm.triggerAutomaticSubmission = async function(e, fieldId) {

        if ((e.keyCode == 13 || e.keyCode == 9) && !e.shiftKey) {
            await vm.scanStation.checkLastThenSubmit(e, vm.scanStation.scanStationObj.id, fieldId);
        }

        if (e.type == 'click' && vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].submitDataField != null && vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId].submitDataField == 1) {
            //TODO Raf we will need to make a ticket to see how we can resolve this and remove the timeout, problem is we need to let other rules finish happening from the response of the data
            setTimeout(async function () {
                await vm.scanStation.checkAllRulesThenAddData(vm.scanStation.scanStationObj.dataToAdd, vm.scanStation.scanStationObj.id);
            }, 500);
        }
    }

    /**
     * Gets the list of actions to load for a field, uses the default hardcoded json if none are set
     * This function also gets the full list so the user can build their own actions
     * @param fieldParameters
     * @returns {Promise<void>}
     */
    vm.getDefaultFieldActions = async function (fieldParameters) {

        let listOfActions = await FieldActionsService.getFieldActionsList(fieldParameters, vm.scanStation.scanStationObj.defaultFieldActionsList);

        vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.fieldIDForUpdatingRules].customFieldActionList = angular.copy(listOfActions);

        $scope.$apply();

    }

    vm.showPopup = ($event) => {

        // set of the class for the #modal get it from the body class
        vm.classToSet = $('body').hasClass('dark') ? 'dark' : 'light';

        // Set of the class so we can have a modal with the current theme
        // some tables and other things rely on the top level skin setting which is declared outside of the skin-setting-dark class
        $('#modal').removeClass()
            .addClass(vm.classToSet)
            .addClass('skin-setting-' + vm.classToSet)
            .show();

        // Showing the alert dialog
        $mdDialog.show({
            controller: FieldActionsController,
            controllerAs: 'fieldActions',
            templateUrl: 'components/field-actions/template/popup.html',
            parent: angular.element(document.querySelector('#modal')),
            targetEvent: $event,
            scope: $scope,
            preserveScope: true,
        });

    }

    /**
     * This method will hide the actual alert that is open.
     * @param event
     */
    vm.hideAlert = async () => {
        $('#modal').removeClass(vm.classToSet).hide();
        vm.scanStation.showingFieldFlow = false;
        $mdDialog.cancel();
    }


    /*
    Removes one of the actions from the actions list for a field
     */
    vm.removeActionFromList = (beforeOrAfterKeyPress, index) => {
        vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.fieldIDForUpdatingRules].customFieldActionList[beforeOrAfterKeyPress].splice(index, 1);
    }

    /*
    Adds to the actions from the actions list for a field
    @param beforeOrAfterKeyPress
    */
    vm.addActionToList = (beforeOrAfterKeyPress) => {

        let errorAlert = 'Please enter the Order Number and select an action'

        if (beforeOrAfterKeyPress == 'before_keypress') {

            if (vm.beforeKeyPressNewOrderId === null || vm.beforeKeyPressNewOrderId === '' || vm.beforeKeyPressNewAction === null || vm.beforeKeyPressNewAction === '') {
                alert(errorAlert);
                return true;
            }
        }

        if (beforeOrAfterKeyPress == 'after_keypress') {

            if (vm.afterKeyPressNewOrderId === null || vm.afterKeyPressNewOrderId === '' || vm.afterKeyPressNewAction === null || vm.afterKeyPressNewAction === '') {
                alert(errorAlert);
                return true;
            }
        }


        vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.fieldIDForUpdatingRules].customFieldActionList[beforeOrAfterKeyPress].push(
            {
                "order_id": (beforeOrAfterKeyPress == 'before_keypress') ? vm.beforeKeyPressNewOrderId : vm.afterKeyPressNewOrderId,
                "action": (beforeOrAfterKeyPress == 'before_keypress') ? vm.beforeKeyPressNewAction : vm.afterKeyPressNewAction
            }
        )

        vm.beforeKeyPressNewOrderId = null;
        vm.afterKeyPressNewOrderId = null;
        vm.beforeKeyPressNewAction = null;
        vm.afterKeyPressNewAction = null;

    }


    /**
     * Bring the list back to the original one
     * @returns {Promise<void>}
     */
    vm.resetToDefaultList = async () => {
        vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.fieldIDForUpdatingRules].customFieldActionList = vm.scanStation.scanStationObj.defaultFieldActionsList;
        $scope.$apply();
    }

    /**
     * Update the field parameter
     */
    vm.setScanStationFieldParameter = () => {
        vm.scanStation.setScanStationFieldParameter(vm.scanStation.fieldIDForUpdatingRules, 'customFieldActionList', vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.fieldIDForUpdatingRules].customFieldActionList,'force-array-to-json-with-array');
    }

    /**
     * This is used so the field actions list can handle arrays with keys or arrays without keys
     * @param obj
     */
    vm.convertObjectToArray = (obj) => {
        return Object.keys(obj).map(key => obj[key]);
    };


    /**
     * This is to ensure the field actions will always be an array when loading on the front end as its sometimes possible they will be ob
     */

    vm.ensureArray = (data) => {
        if (Array.isArray(data)) {
            // It's already an array, return as is.
            return data;
        } else if (typeof data === 'object' && data !== null) {
            // It's an object, convert to an array.
            return Object.keys(data).map(key => data[key]);
        } else {
            // It's neither an array nor an object, or it's null.
            return [];
        }
    }
}

export default FieldActionsController;